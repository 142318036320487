











import { Component, Vue, Prop } from "vue-property-decorator";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import { formatPrice } from "@/utils/string";

@Component({
  components: {
    SectionTitle,
  },
})
export default class BigNumber extends Vue {
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: [String, Number], default: "" }) value!:
    | string
    | number
    | null
    | undefined;
  @Prop({ type: Boolean, default: true }) formatPrice!: boolean;
  @Prop({ type: Boolean, default: false }) dense!: boolean;

  get showTitle(): boolean {
    return this.label.length > 0;
  }

  get displayValue(): string {
    if (this.value === null || this.value === undefined) {
      return "N/A";
    }

    if (this.formatPrice) {
      return formatPrice(this.value);
    }

    return this.value.toString();
  }
}
