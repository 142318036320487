










































import { Component, Vue, Prop } from "vue-property-decorator";
import { ProjectTransactionMultiples } from "@/entity/ProjectSales";
import DetailLayoutWrapper from "@/components/detail-page/DetailLayoutWrapper.vue";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import BigNumber from "@/components/detail-page/BigNumber.vue";

@Component({
  components: {
    DetailLayoutWrapper,
    SectionTitle,
    BigNumber,
  },
})
export default class ProjectSalesCalculatedMultiples extends Vue {
  @Prop({ type: Object, required: true })
  transactionMultiples!: ProjectTransactionMultiples;

  safeValue(value: number | null): string {
    if (value === null) return "N/A";

    return value.toFixed(1);
  }
}
