


















import { Component, Vue, Prop } from "vue-property-decorator";
import { ProjectSalesWithTransactionMultiples } from "@/entity/ProjectSales";
import ProjectsService from "@/services/ProjectsService";
import Loader from "@/components/common/Loader.vue";
import MissingDataText from "@/components/common/MissingDataText.vue";
import Project from "@/entity/Project";
import ProjectSalesDetail from "@/components/project-sales/ProjectSalesDetail.vue";
import ProjectSalesCalculatedMultiples from "@/components/project-sales/ProjectSalesCalculatedMultiples.vue";
import SectionHeading from "@/components/detail-page/SectionHeading.vue";

@Component({
  components: {
    ProjectSalesDetail,
    ProjectSalesCalculatedMultiples,
    Loader,
    MissingDataText,
    SectionHeading,
  },
})
export default class ProjectSalesView extends Vue {
  @Prop({ type: Object, required: true }) project!: Project;

  projectSales: null | ProjectSalesWithTransactionMultiples = null;
  isLoading = false;

  async fetchSales(): Promise<void> {
    this.projectSales = await ProjectsService.getSalesData(
      this.project.id as number
    );
  }

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;
      await this.fetchSales();
    } finally {
      this.isLoading = false;
    }
  }

  created() {
    this.loadData();
  }
}
