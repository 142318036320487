















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ProjectSales } from "@/entity/ProjectSales";
import DetailLayoutWrapper from "@/components/detail-page/DetailLayoutWrapper.vue";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import BigNumber from "@/components/detail-page/BigNumber.vue";
import ItemList from "@/components/detail-page/ItemList.vue";
import { formatTimestamp } from "@/utils/date";
import Project from "@/entity/Project";

@Component({
  components: {
    DetailLayoutWrapper,
    SectionTitle,
    BigNumber,
    ItemList,
  },
})
export default class ProjectSalesDetail extends Vue {
  @Prop({ type: Object, required: true }) projectSales!: ProjectSales;
  @Prop({ type: Object, required: true }) project!: Project;

  formatTimestamp = formatTimestamp;
}
